<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-row>
        <v-col><h2>Sponsorship</h2></v-col>
        </v-row>
      <v-card-title></v-card-title>
       <!-- BOC:[tabs] -->
        <ListAll :url="`${$api.servers.sso}/api/v1/${$_getLocale()}/educator/institution/${$route.params.id}/sponsorship`" :headers="headers" :callbackClick="clickRead"></ListAll>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import ListAll from "../../components/Datatable/ListAll.vue";
export default {
  components: {
    ListAll,
  },
  computed: {
    size() {
      const size = { xs: "small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    gameUrl: null,
    url: null,
    headers: [],
  }),
  created() {
    this.breadcrumbs = [
      {
        text: "Dashboard",
        to: { name: "PageEducatorDashboard" },
        exact: true,
      },
      {
        text: "My Institution",
        to: {
          name: "PageEducatorInstitutionDashboard",
          params: { id: this.$route.params.id },
        },
        exact: true,
      },
      {
        text: "Sponsorship",
        to: {
          name: "PageEducatorInstitutionSponsorship",
          params: { id: this.$route.params.id },
        },
        exact: true,
      },
    ];
    //
    this.headers = [
      { text: "Allocated Seat", value: "seat" },
      { text: "Available Seat", value: "availableSeat" },
      { text: "Start", value: "timestampStart" },
      { text: "End", value: "timestampEnd" },
      { text: "", value: "button", align: "end" },
    ];
    //
    this.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/" +
      this.$route.params.id +
      "/classroom";

    this.api.method = "GET";
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/" +
      this.$route.params.id;

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      //this.updateBreadcrumb(resp);
      return resp;
    };
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  methods: {
    clickRead(item) {
      this.$router.push({
        name: "PageEducatorInstitutionSponsorshipStudent",
        params: { parentId: this.$route.params.id, id: item.id },
      });
    },
    // updateBreadcrumb(resp) {
    //   this.breadcrumbs.push({
    //     text: "Dashboard",
    //     to: { name: "PageEducatorDashboard" },
    //     exact: true,
    //   });
    //   this.breadcrumbs.push({
    //     text: resp.name,
    //     to: { name: "PageEducatorInstitutionDashboard",params:{id:this.$route.params.id} },
    //     exact: true,
    //   });
    //   this.breadcrumbs.push({
    //     text: 'Classrooms',
    //     to: { name: "PageEducatorInstitutionClassroom" },
    //     exact: true,
    //   });
    // },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 600px !important;
}
</style>